import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'
import { Container, Row, Col } from 'reactstrap';
import { Title, PublishedDate } from './faq.styled';

class FaqTemplate extends React.PureComponent<{data}> {

  public render() {
    const { markdownRemark } = this.props.data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark

    return (
      <Layout>
        <Container>
          <Row>
            <Col>
              <Title>{frontmatter.title}</Title>
              <PublishedDate>{frontmatter.date}</PublishedDate>
              
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default FaqTemplate

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`