import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 26px;
  color: #555555;
`
export const PublishedDate = styled.div`
  font-size: 14px;
  color: #bbb;
  margin-bottom:40px;
`